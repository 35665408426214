import { useOrder } from '@Pacific-Web-Services/component-library'
import { useLocation } from 'react-router-dom'

export default function useLimitedProduct() {
  const { pathname } = useLocation()
  const { order } = useOrder()
  if (pathname === '/tacos' || order.items.some((item) => item.menuId === 9)) {
    return 'taco'
  } else if (
    pathname === '/event' ||
    order.items.some((item) => item.menuId === 10)
  ) {
    return 'torta'
  }
  // Wayfarer taco night menu id = 9
  return 'pizza'
}
